import { FormEvent, useState } from "react";
import Footer from "../../shared/core/Footer/Footer";
import Nav from "../../shared/core/Nav/Nav";
import { Fade, Slide } from "react-awesome-reveal";
import emailjs from "emailjs-com";
import { useTranslation } from "react-i18next";

export default function Contact() {
  const { t } = useTranslation();
  const [name, setName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [message, setMessage] = useState<string>("");

  const submit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (name && email && message) {
      const serviceId = "service_mzry52u";
      const templateId = "template_4qkoo7i";
      const userId = "Wh2X3Hnzry_EX-uGn";
      const templateParams = {
        name,
        email,
        message,
      };

      emailjs
        .send(serviceId, templateId, templateParams, userId)
        .then((response) => {
          console.log(response);
        })
        .then((error) => {
          console.log(error);
        });

      setName("");
      setEmail("");
      setMessage("");
      showSpan(t('thankYouForContactingUs'));
    } else {
      showSpan(t('contactUsErrorMessage'));
    }
  };

  const showSpan = (message: string) => {
    const id = document.getElementById("statusMessage");
    if (id) {
      if (
        message === t('thankYouForContactingUs')
      ) {
        id.setAttribute("class", "confimation");

        id.innerHTML = message;
      } else {
        id.setAttribute("class", "failed");

        id.innerHTML = message;
      }
      // id.innerHTML = message
    }
  };

  return (
    <div className="flex flex-col min-h-[calc(100dvh)]">
      <Nav />

      {/* Desktop */}
      <div className="hidden xl:flex items-center pt-[5%] container mx-auto flex-1">
        <div className="w-1/2 mr-10 space-y-4">
          <Slide cascade>
            <h1 className="font-source-bold text-secondary text-h1">
              {t('contactUs')}
            </h1>
            <p className="text-large font-source-regular w-2/3 text-text-black leading-normal text-center xl:text-start">
              {t('contactUsSubtitle')}
            </p>
            <div className="flex gap-12 w-5/6 pt-16">
              <Fade cascade>
                <img
                  className="size-16 cursor-pointer hover:scale-110 ease-in-out duration-300"
                  src="/images/contact/Instagram.png"
                />
                <img
                  className="size-16 cursor-pointer hover:scale-110 ease-in-out duration-300"
                  src="/images/contact/Facebook_logo.png"
                />
                <img
                  className="size-16 cursor-pointer hover:scale-110 ease-in-out duration-300"
                  src="/images/contact/X.png"
                />
                <img
                  className="size-16 cursor-pointer hover:scale-110 ease-in-out duration-300"
                  src="/images/contact/LinkedIn.png"
                />
              </Fade>
            </div>
          </Slide>
        </div>
        <div className="w-1/2 pt-14">
          <form
            className="font-source-regular text-[20px] flex flex-col gap-12 pb-4"
            onSubmit={submit}
          >
            <input
              className="border-b-2 border-[#B8B8B8] focus:outline-none focus:ring-0 py-2"
              type="text"
              placeholder={t('fullName')}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <input
              className="border-b-2 border-[#B8B8B8] invalid:border-[#ff0000] focus:outline-none focus:ring-0 py-2"
              type="email"
              placeholder={t('email')}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <div className="relative">
              <textarea
                className="border-b-2 border-[#B8B8B8] focus:outline-none focus:ring-0 resize-none w-full"
                placeholder={t('message')}
                maxLength={3000}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
              <span className="absolute bottom-2 right-5 text-small text-[#B8B8B8]">
                {t('max3000')}
              </span>
            </div>
            <input
              className="self-start font-source-bold uppercase text-large bg-primary cursor-pointer text-text-white py-4 px-12 rounded-3xl transition-colors hover:bg-secondary ease-in-out duration-500"
              type="submit"
              value={t('sendMessageButton')}
            />
          </form>
        </div>
      </div>

      {/* Mobile & Tablet */}
      <div className="xl:hidden p-8 flex flex-col gap-10 pt-[30%] md:pt-[20%]">
        <div>
          <h1 className="font-source-bold text-secondary md:text-h1 text-h2">
            {t('contactUs')}
          </h1>
          <p className="font-source-regular text-text-black text-small md:text-large">
            {t('contactUsSubtitle')}
          </p>
        </div>
        <div>
          <form
            className="font-source-regular text-small flex flex-col gap-6 pb-6"
            onSubmit={submit}
          >
            <input
              className="border-b-2 border-[#B8B8B8] focus:outline-none focus:ring-0 py-2"
              type="text"
              placeholder={t('fullName')}
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <input
              className="border-b-2 border-[#B8B8B8] focus:outline-none focus:ring-0 py-2"
              type="email"
              placeholder={t('email')}
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <div className="relative">
              <textarea
                className="border-b-2 border-[#B8B8B8] focus:outline-none focus:ring-0 resize-none block w-full py-2"
                placeholder={t('message')}
                maxLength={3000}
                value={message}
                onChange={(e) => setMessage(e.target.value)}
              ></textarea>
              <span className="absolute bottom-2 right-3 text-[10px] text-[#B8B8B8]">
                {t('max3000')}
              </span>
            </div>
            <input
              className="self-start font-source-bold uppercase text-small bg-primary cursor-pointer text-text-white py-3 px-7 rounded-3xl"
              type="submit"
              value={t('sendMessageButton')}
            />
          </form>
          <div id="statusMessage" className="text-small"></div>
        </div>
        <div className="flex justify-around items-center">
          <div className="border border-primary w-1/2"></div>
          <p className="px-2 text-primary uppercase font-source-italic">OF</p>
          <div className="border border-primary w-1/2"></div>
        </div>
        <div className="flex justify-between md:justify-around self-center w-10/12">
          <img
            className="size-12 md:size-14 cursor-pointer"
            src="/images/contact/Instagram.png"
          />
          <img
            className="size-12 md:size-14 cursor-pointer"
            src="/images/contact/Facebook_logo.png"
          />
          <img
            className="size-12 md:size-14 cursor-pointer"
            src="/images/contact/X.png"
          />
          <img
            className="size-12 md:size-14 cursor-pointer"
            src="/images/contact/LinkedIn.png"
          />
        </div>
      </div>

      <Footer />
      
    </div>
  );
}
