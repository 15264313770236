import { faLinkedinIn } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface HexagonProps {
  name?: string;
  job_titles: string[];
  linked_in?: string;
  image?: string;
}

export default function Hexagon(props: HexagonProps) {
  const hexagonColor = "#361692";

  return (
    <div className="relative group size-80">
      <svg
        width="100%"
        height="100%"
        viewBox="0 0 330 330"
        className="absolute inset-0"
      >
        <defs>
          <clipPath id="hexagon">
            <path
              d="M114,6 L246,6 A36,36 0 0,1 282,30 L336,132 A36,36 0 0,1 336,168 L282,270 A36,36 0 0,1 246,294 L114,294 A36,36 0 0,1 78,270 L24,168 A36,36 0 0,1 24,132 L78,30 A36,36 0 0,1 114,6"
              transform="rotate(90 170 153)"
            />
          </clipPath>
        </defs>

        <image
          clipPath="url(#hexagon)"
          href={props.image}
          className="overflow-hidden w-full h-full"
        />

        <path
          d="M114,6 L246,6 A36,36 0 0,1 282,30 L336,132 A36,36 0 0,1 336,168 L282,270 A36,36 0 0,1 246,294 L114,294 A36,36 0 0,1 78,270 L24,168 A36,36 0 0,1 24,132 L78,30 A36,36 0 0,1 114,6"
          transform="rotate(90 170 153)"
          fill={hexagonColor}
          className="group-hover:opacity-50 opacity-0 duration-300 ease-in-out"
        />
      </svg>

      <div className="absolute inset-0 flex flex-col items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300 ease-in-out w-[320px] mx-auto ">
        <h3 className="font-source-bold text-[28px] text-text-white mb-3 p-3 text-center">
          {props.name}
        </h3>
        <div className="font-source-regular text-large text-text-white text-center">
          {props.job_titles?.map((title, index) => (
            <div key={index} className="flex justify-center">
              <span>{title}</span>
              {index < props.job_titles.length - 1 && (
                <span className="mx-2"> & </span>
              )}
            </div>
          ))}
        </div>
        {props.linked_in && (
          <a
            href={props.linked_in}
            className="bg-secondary rounded-full flex items-center justify-center shadow-md absolute bottom-5 left-1/2 transform -translate-x-1/3 hover:bg-primary duration-300 ease-in-out"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FontAwesomeIcon
              className="size-7 p-3 text-text-white"
              icon={faLinkedinIn}
            />
          </a>
        )}
      </div>
    </div>
  );
}
