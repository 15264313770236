import Footer from "../../shared/core/Footer/Footer";
import Home from "./Home";
import About from "./About";

export default function Experiencers() {

  return (
    <div className="bg-bg-primary" id="experiencers">
      <Home />
      <About />
      <Footer />
    </div>
  );
}
