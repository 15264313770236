interface LaptopProps {
    scale: number;
    width: string;
    height: string;
}

export default function Laptop({ scale, width, height }: LaptopProps) {
    return (
        <div 
            className="relative bg-icon-light rounded-full flex justify-center items-center drop-shadow-xl" 
            style={{ width, height }}
        >
            <img 
                className="block w-full h-auto object-contain" 
                style={{ transform: `scale(${scale})`}} 
                src="images/mockup-laptop.png" 
                alt="Laptop" 
            />
        </div>
    );
}
