import Home from "./Home";
import Tools from "./Tools";
import Benefits from "./Benefits";
import Footer from "../../shared/core/Footer/Footer";

export default function Providers() {

  return (
    <div className="bg-bg-primary" id="providers">
      <Home />
      <Tools />
      <Benefits />
      <Footer />
    </div>
  );
}
