import "./Nav.scss";
import { Link, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { SelectMenuOption } from "../../components/LanguageSelector/types";
import { COUNTRIES } from "../../components/LanguageSelector/countries";
import LanguageSelector from "../../components/LanguageSelector/selector";
import { getLanguageCode } from "../../../helpers/functions/string";

interface Props {
  purple?: boolean;
}

export default function Nav(props: Props) {
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const [country, setCountry] = useState<SelectMenuOption["value"]>("GB");
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);
  
  const handleClick = () => {
    setMenuOpen(!menuOpen);
  };

  const handleCountryChange = (value: SelectMenuOption["value"], lng: SelectMenuOption["lng"]) => {
    setCountry(value);
    setLanguage(lng);

    // Store values
    localStorage.setItem('languageSelected', lng);
  };

  useEffect(() => {
    if (menuOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
  }, [menuOpen]);

  // Select selected language
  useEffect(() => {
    i18n.changeLanguage(language);
  }, [language]);

  // Select init language and store in localstorage
  useEffect(() => {
    // Set initial language from localStorage if available
    let storedLanguage = localStorage.getItem("languageSelected");
    if (storedLanguage) {
      setLanguage(storedLanguage);
    } else {
      // Take language from browser
      setLanguage(navigator.language);
      storedLanguage = getLanguageCode(navigator.language);
    }

    // Set initial country based on language
    const selectedCountry = COUNTRIES.find(option => option.lng === storedLanguage);
    if (selectedCountry) {
      setCountry(selectedCountry.value);
    }
  }, []);

  return (
    <nav>
      {/* Desktop Navbar */}
      <nav
        className=" w-full md:mt-[42px] lg:mt-[55px]
                            hidden md:flex justify-center 
                            font-source-regular "
      >
        <div
          className="w-11/12 flex justify-between items-center"
        >
          <Link
            to="/"
          >
            <div className="flex items-center space-x-2">
              <img
                src="./images/yaldo_icon_main_color.svg"
                className="md:w-[29px] lg:w-[33px] pt-4 cursor-pointer"
                alt="Yaldo Icon"
              />
              <img
                src="./images/logo.svg"
                className="md:w-[158px] lg:w-[183px] cursor-pointer"
                alt="Yaldo Logo Name"
              />
            </div>
          </Link>

          <div
            className="flex justify-around items-center md:space-x-[22px] lg:space-x-[36px] 
                                    shadow-[0_5px_13px_0px_rgba(0,0,0,0.3)] shadow[#00000029]
                                    rounded-[25px] md:px-[20px] lg:px-[32px] md:h-[86px] lg:h-[98px]"
          >
            <Link
              to="/"
              className={`lg:text-large 
                                    ${
                                      location.pathname === "/"
                                        ? "text-primary font-source-bold nav-dot uppercase"
                                        : "text-text-black uppercase"
                                    }`}
            >
              {t('home')}
            </Link>

            <Link
              to="/providers"
              className={`lg:text-large 
                            ${
                              location.pathname === "/providers"
                                ? "text-primary font-source-bold nav-dot uppercase"
                                : "text-text-black uppercase"
                            }`}
            >
              {t('providers')}
            </Link>

            <Link
              to="/experiencers"
              className={`lg:text-large 
                            ${
                              location.pathname === "/experiencers"
                                ? "text-primary font-source-bold nav-dot uppercase"
                                : "text-text-black uppercase"
                            }`}
            >
              {t('experiencers')}
            </Link>

            <Link
              to="/contact"
              className={`lg:text-large 
                            ${
                              location.pathname === "/contact"
                                ? "text-primary font-source-bold nav-dot uppercase"
                                : "text-text-black uppercase"
                            }`}
            >
              {t('contact')}
            </Link>
            <LanguageSelector
              id={"countries"}
              open={isOpen}
              onToggle={() => setIsOpen(!isOpen)}
              onChange={(value, lng) => handleCountryChange(value, lng)}
              selectedValue={COUNTRIES.find(option => option.value === country) as SelectMenuOption}
            />
          </div>
        </div>
      </nav>

      {!menuOpen && (
        <nav
          className="bg-text-white
                            w-full 
                            flex justify-between items-center md:hidden
                            font-source-regular 
                            p-6
                            drop-shadow-xl"
        >
          <div className="flex items-center space-x-1">
            <img
              src="./images/yaldo_icon_main_color.svg"
              className="w-[17px] pt-2 cursor-pointer"
              alt="Yaldo Icon"
            />
            <img 
              src="./images/logo.svg" 
              className="w-[100px] cursor-pointer"
              alt="Yaldo Logo Name"
            />
          </div>

          <button className="text-primary text-h4" onClick={handleClick}>
            ☰ {/* Hamburger icon */}
          </button>
        </nav>
      )}

      {menuOpen && (
        <div className="bg-primary pt-[28px] fixed w-full h-full overflow-hidden ">
          <div className="flex justify-between pl-[24px] pr-[24px] pb-[120px] text-text-white font-source-regular text-h2 p-0 leading-none">
            <div className="flex items-center space-x-1 p-0 leading-none">
              <img
                src="./images/yaldo_icon_white.svg"
                className="w-[17px] pt-2"
                alt="Yaldo Logo"
              />
              <img 
                src="./images/logo_white.svg" 
                className="w-[100px]"
                alt="Yaldo Logo" 
              />
            </div>
            <button onClick={handleClick} className="p-0 m-0">
              <img
                src="./images/x-symbol.svg"
                className="invert w-[25px]"
                alt="Yaldo Logo"
              />
            </button>
          </div>
          <div className="flex flex-col font-source-regular text-h3 text-text-white -mt-16 pl-[76px] space-y-[50px]">
            <Link
              to="/"
              className={`lg:text-large
                    ${
                      location.pathname === "/"
                        ? "text-text-white font-source-bold nav-dotmobile uppercase"
                        : "text-text-white uppercase"
                    }`}
            >
              {t('home')}
            </Link>
            <Link
              to="/providers"
              className={`lg:text-large 
                    ${
                      location.pathname === "/providers"
                        ? "text-text-white font-source-bold nav-dotmobile uppercase"
                        : "text-text-white uppercase"
                    }`}
            >
              {t('providers')}
            </Link>
            <Link
              to="/experiencers"
              className={`lg:text-large 
                    ${
                      location.pathname === "/experiencers"
                        ? "text-text-white font-source-bold nav-dotmobile uppercase"
                        : "text-text-white uppercase"
                    }`}
            >
              {t('experiencers')}
            </Link>

            <Link
              to="/contact"
              className={`lg:text-large 
                    ${
                      location.pathname === "/contact"
                        ? "text-text-white font-source-bold nav-dotmobile uppercase"
                        : "text-text-white uppercase"
                    }`}
            >
              {t('contact')}
            </Link>
          </div>
          <div className="text-text-white mx-20 mt-5">
            <LanguageSelector
                id={"countries"}
                open={isOpen}
                onToggle={() => setIsOpen(!isOpen)}
                onChange={(value, lng) => handleCountryChange(value, lng)}
                selectedValue={COUNTRIES.find(option => option.value === country) as SelectMenuOption}
            />
          </div>
          <img
            src="./images/yaldo_icon_secondary_color.svg"
            className="fixed w-[400px] -right-16 top-64 pointer-events-none"
            alt="Yaldo Logo Name"
          />
        </div>
      )}
    </nav>
  );
}
