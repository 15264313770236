import { Fade } from "react-awesome-reveal";
import { useTranslation } from "react-i18next";

export default function About() {
  const { t } = useTranslation();

  return (
    <div
      id="about"
      className="container mx-auto h-full xl:min-h-[calc(100dvh)]"
    >
      {/* Desktop */}
      <div className="hidden xl:block">
        <h1 className="text-h2 font-source-bold text-secondary leading-tight text-center pt-6">
          {t('howGoes')} <span className="text-primary">{t('yaldo')}</span> {t('inWork')}
        </h1>
        <div className="flex pt-28 space-x-20 justify-center items-center">
          <div className="w-1/4 space-y-20">
            <Fade triggerOnce={true} fraction={1}>
              <div className="flex flex-col items-end">
                <div className=" bg-primary text-text-white text-[75px] px-8 py-4 font-source-regular rounded-tl-[40px] rounded-tr-[10px] rounded-br-[40px] rounded-bl-[10px] leading-none">
                  1
                </div>
                <h3 className="font-source-semibold text-large text-right pt-2">
                  {t('perfectMatchmaker')}
                </h3>
                <p className="font-source-regular text-small text-right pt-4">
                  {t('perfectMatchmakerSubtitle')}
                </p>
              </div>
            </Fade>
            <Fade delay={1000} triggerOnce={true} fraction={1}>
              <div className="flex flex-col items-end">
                <div className=" bg-primary text-text-white text-[75px] px-8 py-4 font-source-regular rounded-tl-[40px] rounded-tr-[10px] rounded-br-[40px] rounded-bl-[10px] leading-none">
                  3
                </div>
                <h3 className="font-source-semibold text-large text-right pt-2">
                  {t('spontaniousInvite')}
                </h3>
                <p className="font-source-regular text-small text-right pt-4">
                  {t('spontaniousInviteSubtitle')}
                </p>
              </div>
            </Fade>
          </div>

          <div className="w-2/4 z-0 relative">
            <img
              className="w-[50%] h-[50%] relative left-[25%]"
              src="images/mockup-phone-1.png"
              alt="yaldo_phone"
            />
            <div className="size-[400px] bg-primary rounded-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 -z-10 shadow shadow-text-black"></div>
            <div className="size-[500px] bg-secondary rounded-full absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 -z-20 drop-shadow-2xl shadow-text-black"></div>
          </div>
          <div className="w-1/4 space-y-20">
            <Fade delay={500} triggerOnce={true} fraction={1}>
              <div className="flex flex-col items-start">
                <div className=" bg-primary text-text-white text-[75px] px-8 py-4 font-source-regular rounded-tl-[10px] rounded-tr-[40px] rounded-br-[10px] rounded-bl-[40px] leading-none">
                  2
                </div>
                <h3 className="font-source-semibold text-large text-left pt-2">
                  {t('simplePlanner')}
                </h3>
                <p className="font-source-regular text-small text-left pt-4">
                  {t('simplePlannerSubtitle')}
                </p>
              </div>
            </Fade>
            <Fade delay={1500} triggerOnce={true} fraction={1}>
              <div className="flex flex-col items-start">
                <div className=" bg-primary text-text-white text-[75px] px-8 py-4 font-source-regular rounded-tl-[10px] rounded-tr-[40px] rounded-br-[10px] rounded-bl-[40px] leading-none">
                  4
                </div>
                <h3 className="font-source-semibold text-large text-left pt-2">
                  {t('idealBillSplitter')}
                </h3>
                <p className="font-source-regular text-small text-left pt-4">
                  {t('idealBillSplitterSubtitle')}
                </p>
              </div>
            </Fade>
          </div>
        </div>
      </div>

      {/* Mobile */}
      <div className="xl:hidden px-8 pb-16">
        <h3 className="text-h3 font-source-bold text-secondary leading-tight pb-8 text-center">
          {t('howGoes')} <span className="text-primary">{t('yaldo')}</span> {t('inWork')}
        </h3>

        <div className="space-y-20">
          <div className="flex flex-col items-end">
            <div className=" bg-primary text-text-white text-[75px] px-8 py-4 font-source-regular rounded-tl-[40px] rounded-tr-[10px] rounded-br-[40px] rounded-bl-[10px] leading-none">
              1
            </div>
            <h3 className="font-source-semibold text-large text-right pt-2">
              {t('perfectMatchmaker')}
            </h3>
            <p className="font-source-regular text-small text-right pt-4">
              {t('perfectMatchmakerSubtitle')}
            </p>
          </div>
          <div className="flex flex-col items-start">
            <div className=" bg-primary text-text-white text-[75px] px-8 py-4 font-source-regular rounded-tl-[10px] rounded-tr-[40px] rounded-br-[10px] rounded-bl-[40px] leading-none">
              2
            </div>
            <h3 className="font-source-semibold text-large text-left pt-2">
              {t('simplePlanner')}
            </h3>
            <p className="font-source-regular text-small text-left pt-4">
              {t('simplePlannerSubtitle')}
            </p>
          </div>
          <div className="flex flex-col items-end">
            <div className=" bg-primary text-text-white text-[75px] px-8 py-4 font-source-regular rounded-tl-[40px] rounded-tr-[10px] rounded-br-[40px] rounded-bl-[10px] leading-none">
              3
            </div>
            <h3 className="font-source-semibold text-large text-right pt-2">
              {t('spontaniousInvite')}
            </h3>
            <p className="font-source-regular text-small text-right pt-4">
              {t('spontaniousInviteSubtitle')}
            </p>
          </div>
          <div className="flex flex-col items-start">
            <div className=" bg-primary text-text-white text-[75px] px-8 py-4 font-source-regular rounded-tl-[10px] rounded-tr-[40px] rounded-br-[10px] rounded-bl-[40px] leading-none">
              4
            </div>
            <h3 className="font-source-semibold text-large text-left pt-2">
              {t('idealBillSplitter')}
            </h3>
            <p className="font-source-regular text-small text-left pt-4">
              {t('idealBillSplitterSubtitle')}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
