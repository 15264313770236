import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconProp } from "@fortawesome/fontawesome-svg-core";

interface MissionProps {
  icon: IconProp;
  picture: string;
  thema: string;
  text: string;
  iconColor: string;
}

export default function Mission(props: MissionProps) {
  return (
    <div className="w-[300px] h-[430px] md:w-[400px] md:h-[530px] shadow-md relative rounded-[20px] hover:scale-110 ease-in-out duration-300">
      <div className="h-3/5 flex justify-center items-center">
        <div className=" bg-secondary absolute top-0 bottom-0 w-full h-3/5 opacity-40 rounded-t-[20px]" />
        <h1 className="absolute font-source-bold text-text-white text-h3 uppercase">
          {props.thema}
        </h1>
        <img
          src={props.picture}
          alt={`${props.thema} related image`}
          className="object-cover w-full h-full rounded-t-[20px] "
        />
      </div>

      <FontAwesomeIcon
        icon={props.icon}
        className={` absolute left-1/2 transform -translate-x-1/2 -translate-y-[70%] size-4 md:size-10 ${props.iconColor} text-text-white p-4 md:p-6 font-source-regular rounded-tl-[20px] md:rounded-tl-[40px] rounded-tr-[5px] md:rounded-tr-[10px] rounded-br-[20px] md:rounded-br-[40px] rounded-bl-[5px] md:rounded-bl-[10px] border-2 md:border-4 border-text-white shadow-md  `}
      />
      <div className="flex items-center justify-center h-2/5 pt-2 px-12 font-source-regular text-small text-center">
        <p>{props.text}</p>
      </div>
    </div>
  );
}
