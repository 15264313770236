import { Link } from "react-router-dom";
import { Slide } from "react-awesome-reveal";
import Nav from "../../shared/core/Nav/Nav";
import Phone from "../../shared/components/Phone/Phone";
import { useTranslation } from "react-i18next";

export default function Home() {
  const { t } = useTranslation();

  return (
    <div>
      {/* Desktop */}
      <div className="hidden xl:grid grid-rows-[200px_1fr_auto] min-h-[calc(100dvh)]">
        <div>
          <Nav />
        </div>

        <div className="mx-auto container flex justify-around items-center">
          <div className="space-y-10 w-full xl:w-1/2 content-center">
            <Slide cascade triggerOnce={true}>
              <h1 className="text-h1 font-source-bold text-primary leading-tight text-center xl:text-start">
                <span className="text-secondary">{t('onePlatform')},</span> {t('infiniteAdventures')}
              </h1>
              <p className="text-large font-source-regular w-2/3 text-text-black leading-normal text-center xl:text-start">
                {t('infiniteAdventuresSubtitle')}
              </p>

              <div className="space-x-4 mt-20">
                <Link 
                    to="/experiencers"
                    className="text-large font-source-bold text-text-white uppercase bg-primary py-4 px-8 rounded-3xl hover:bg-secondary transition ease-in-out duration-300"
                  >
                    {t('downloadTheApp')}
                </Link>
              </div>
            </Slide>
          </div>

          <div className="w-1/2">
            <Phone />
          </div>
        </div>

        <div className="h-fit">
          <img
            className="invert pb-1 w-8 mx-auto animate-bounce"
            src="images/yaldoScroll.png"
            alt="yaldo_scroll"
          />
        </div>
      </div>

      {/* Mobile and iPad */}
      <div className="xl:hidden grid md:min-h-[calc(100dvh)] md:grid-rows-[200px_1fr] grid-rows-[100px_1fr] mb-10">
        <div>
          <Nav />
        </div>
        <div className="flex flex-col mx-4 items-center text-center space-y-6 md:space-y-0">
          <h2 className="text-h3 md:text-h1 font-source-bold text-primary leading-tight">
            <span className="text-secondary">{t('onePlatform')},</span> {t('infiniteAdventures')}
          </h2>
          <p className="text-small md:text-large font-source-regular text-text-black leading-normal">
            {t('infiniteAdventuresSubtitle')}
          </p>
          <div className="relative">
            <img
              src="images/mockup-phone-1.png"
              className="w-full h-96 mb-20"
            />
            <div className="text-small absolute top-[20%] right-2/3 bg-icon-dark px-6 py-3 md:text-large text-text-white font-source-semibold rounded-tl-[20px] rounded-tr-[5px] rounded-br-[20px] rounded-bl-[5px]">
              {t('basedOnInterests')}
            </div>
            <div className="text-small absolute top-[55%] left-2/3 bg-icon-dark px-6 py-3 text-text-white md:text-large font-source-semibold rounded-tr-[20px] rounded-tl-[5px] rounded-bl-[20px] rounded-br-[5px]">
              {t('inviteFriendsEasily')}
            </div>
          </div>
          <div className="space-x-4">
            <Link 
                to="/experiencers"
                className="text-small md:text-large self-center font-source-bold text-text-white uppercase bg-primary py-3 px-6 rounded-3xl"
              >
                {t('downloadTheApp')}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
