import i18next from "i18next";
import { initReactI18next } from "react-i18next";
import { en, fr, nl } from "./translations/_index";

const resources = {
  en: {
    translation: en,
  },
  fr: {
    translation: fr,
  },
  nl: {
    translation: nl,
  },
}

i18next.use(initReactI18next).init({
  debug: false,
  lng: 'en',
  compatibilityJSON: 'v4',
  //language to use if translation in user language is not available
  fallbackLng: 'en',
  resources
})

export default i18next;
