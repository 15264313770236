import ContactComponent from "./Contact";

export default function Contact() {

  return (
    <div className="bg-bg-primary" id="contact">
      <ContactComponent />
    </div>
  );
}
