import { useTranslation } from "react-i18next";

export default function PrivacyPolicy() {
    const { t } = useTranslation();

    return (
        <div style={{ maxWidth: '80%', margin: '0 auto' }}>
            <h1>{t('privacyPolicy.title')}</h1>
            <p><strong>{t('privacyPolicy.introduction')}</strong></p>
            <p>{t('privacyPolicy.description')}</p>

            <h2>{t('privacyPolicy.collection')}</h2>
            <p>{t('privacyPolicy.personalInfo')}</p>
            <ul>
                <li><strong>{t('privacyPolicy.sensitiveData')}</strong></li>
                <li>{t('privacyPolicy.userActivity')}</li>
            </ul>

            <h2>{t('privacyPolicy.usage')}</h2>
            <p>{t('privacyPolicy.dataUsage')}</p>
            <ul>
                <li>{t('privacyPolicy.enhanceExperience')}</li>
                <li>{t('privacyPolicy.processTransactions')}</li>
                <li>{t('privacyPolicy.communicateInfo')}</li>
            </ul>
            <p>{t('privacyPolicy.dataSharing')}</p>
            <ul>
                <li>{t('privacyPolicy.serviceProviders')}</li>
                <li>{t('privacyPolicy.legalAuthorities')}</li>
            </ul>

            <h2>{t('privacyPolicy.security')}</h2>
            <p>{t('privacyPolicy.encryption')}</p>
            <ul>
                <li><strong>{t('privacyPolicy.accessControl')}</strong></li>
                <li>{t('privacyPolicy.anonymization')}</li>
                <li>{t('privacyPolicy.regularAudits')}</li>
            </ul>

            <h2>{t('privacyPolicy.riskManagement')}</h2>
            <p>{t('privacyPolicy.linddunFramework')}</p>
            <ul>
                <li>{t('privacyPolicy.unauthorizedAccess')}</li>
                <li>{t('privacyPolicy.unintendedDisclosure')}</li>
                <li>{t('privacyPolicy.insufficientControl')}</li>
            </ul>

            <h2>{t('privacyPolicy.userRights')}</h2>
            <p>{t('privacyPolicy.accessCorrectDelete')}</p>

            <h2>{t('privacyPolicy.changes')}</h2>
            <p>{t('privacyPolicy.policyUpdates')}</p>

            <h2>{t('privacyPolicy.about')}</h2>
            <p>{t('privacyPolicy.companyInfo')}</p>

            <h2>{t('privacyPolicy.contact')}</h2>
            <p>{t('privacyPolicy.emailLink')}</p>
        </div>
    )
}
