import { Fade, Zoom } from "react-awesome-reveal";
import { useTranslation } from "react-i18next";

export default function Tools() {
  const { t } = useTranslation();

  return (
    <div id="tools">
      <div className="mt-10 hidden xl:block min-h-screen container mx-auto content-center">
        <Zoom triggerOnce={true}>
          <div className="bg-icon-dark py-20 space-y-20 min-h-full rounded-[100px] shadow-[0_0_99px_rgba(0,0,0,0.3)] shadow-primary">
            <h1 className="text-icon-light text-center text-h2 font-source-bold">
              {t('allToolsInOne')}
            </h1>
            <Fade triggerOnce={true}>
              <div className="flex flex-1 min-h-[40%] mx-40 space-x-8">
                <div className="bg-primary rounded-[29px] text-center flex-1 flex flex-col py-6 shadow-primary shadow-[0_0_99px_rgba(0,0,0,0.3)]">
                  <p className="text-text-white text-large font-source-bold mb-10">
                    {t('reachCustomers')}
                  </p>
                  <div className="flex flex-wrap gap-y-6 gap-x-8 justify-center">
                    <img src="/images/providers/Vivabox.png" />
                    <img src="/images/providers/Tiktok.png" />
                    <img src="/images/providers/Facebook.png" />
                    <img src="/images/providers/Youtube.png" />
                    <img src="/images/providers/Bongo.png" />
                  </div>
                </div>
                <div className="bg-primary rounded-[29px] text-center flex-1 flex flex-col py-6 shadow-primary shadow-[0_0_99px_rgba(0,0,0,0.3)]">
                  <p className="text-text-white text-large font-source-bold">
                    {t('bookingsAndAdministration')}
                  </p>
                  <div className="flex flex-wrap gap-y-10 gap-x-8 justify-center my-auto">
                    <img src="/images/providers/Doodle.png" />
                    <img src="/images/providers/Excel.png" />
                    <img src="/images/providers/Reservio.png" />
                    <img src="/images/providers/Calendly.png" />
                  </div>
                </div>
                <div className="bg-primary rounded-[29px] text-center flex-1 flex flex-col py-6 shadow-primary shadow-[0_0_99px_rgba(0,0,0,0.3)]">
                  <p className="text-text-white text-large font-source-bold">
                    {t('contactWithCustomers')}
                  </p>
                  <div className="flex flex-wrap gap-y-6 gap-x-8 justify-center my-auto">
                    <img src="/images/providers/Messenger.png" />
                    <img src="/images/providers/Outlook.png" />
                    <img src="/images/providers/Whatsapp.png" />
                  </div>
                </div>
              </div>
            </Fade>
            <Fade delay={250} triggerOnce={true}>
              <div className="flex justify-around items-center bg-primary rounded-[29px] mx-40 py-[35px] shadow-primary shadow-[0_0_99px_rgba(0,0,0,0.3)]">
                <div className="text-text-white text-large font-source-bold w-2/3 leading-normal text-center xl:text-start">
                  {t('allToolsInOneSubtitle')}
                </div>
                <div className="flex items-center space-x-2">
                  <img
                    src="./images/yaldo_icon_white.svg"
                    className="sm:w-[22px] lg:w-[32px] pt-4 block max-w-full"
                  />
                  <img
                    src="./images/logo_white.svg"
                    className="sm:w-[158px] lg:w-[170px] block max-w-full"
                  />
                </div>
              </div>
            </Fade>
          </div>
        </Zoom>
      </div>
    </div>
  );
}
