import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Nav from "../../shared/core/Nav/Nav";
import Laptop from "../../shared/components/Laptop/Laptop";
import { JackInTheBox, Slide } from "react-awesome-reveal";

export default function Home() {
  const { t } = useTranslation();

  return (
    <div id="home">
      <div className="hidden md:grid grid-rows-[200px_1fr_auto] min-h-[calc(100dvh)]">
        <div>
          <Nav />
        </div>

        <div className="mx-auto container flex justify-around items-center">
          <div className="space-y-10 w-full xl:w-1/2 content-center">
            <Slide
              cascade
              triggerOnce={true}
              className="flex flex-col items-center xl:block"
            >
              <h1 className="text-h1 font-source-bold text-primary leading-tight text-center xl:text-start">
                <span className="text-secondary">{t('onePlatform')},</span> {t('infiniteOffers')}
              </h1>
              <p className="text-large font-source-regular w-2/3 text-text-black leading-normal text-center xl:text-start">
                {t('infiniteOffersSubtitle')}
              </p>

              <div className="space-x-4 mt-20">
                <Link 
                    to="https://development-platform-project-redirect.loca.lt"
                    className="text-large font-source-bold text-text-white uppercase bg-primary py-4 px-8 rounded-3xl hover:bg-secondary transition ease-in-out duration-300"
                  >
                    {t('discoverPlatformButton')}
                </Link>
              </div>
            </Slide>
          </div>

          <div className="md:hidden xl:block xl:w-1/2 self-center relative z-0">
            <div className="object-contain hidden xl:flex h-full w-full justify-center items-center z-0">
              <JackInTheBox triggerOnce={true} delay={300}>
                <Laptop scale={1.3} width="36rem" height="36rem" />
              </JackInTheBox>
            </div>
          </div>
        </div>

        <div className="h-fit">
          <img
            className="invert mb-1 w-8 mx-auto animate-bounce"
            src="images/yaldoScroll.png"
            alt="yaldo_scroll"
          />
        </div>
      </div>

      {/* Mobile */}
      <div className="grid grid-rows-[100px_1fr] md:hidden">
        <div>
          <Nav />
        </div>
        <div className="flex flex-col items-center gap-8 text-center px-6">
          <h1 className="text-h2 font-source-bold text-primary leading-none">
            <span className="text-secondary">{t('onePlatform')},</span> {t('infiniteOffers')}
          </h1>
          <p className="text-small font-source-regular text-text-black pr-10">
            {t('infiniteOffersSubtitle')}
          </p>
          <div className="self-center">
            <Laptop scale={1.3} width="18rem" height="18rem"></Laptop>
          </div>
          <div className="space-x-4 mt-20">
            <Link 
                to="https://development-platform-project-redirect.loca.lt"
                className="bg-primary text-text-white self-center font-source-bold py-3 px-7 rounded-3xl"
              >
                {t('discoverPlatformButton')}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}