import ReactDOM from 'react-dom/client';
import reportWebVitals from './types/reportWebVitals';
import './index.scss';
import './helpers/localization/i18n.config';

import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Home from './routes/Home';
import Providers from './routes/Providers';
import Experiencers from './routes/Experiencers';
import Contact from './routes/Contact';
import PrivacyPolicy from './routes/PrivacyPolicy';
import ScrollToTop from './shared/components/ScrollToTop';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

root.render(
  <BrowserRouter>
    <ScrollToTop>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/providers" element={<Providers />} />
        <Route path="/experiencers" element={<Experiencers />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/privacy-policy" element={<PrivacyPolicy />} />
      </Routes>
    </ScrollToTop>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
