import { useTranslation } from "react-i18next";
import { COUNTRIES } from "./countries";
import { SelectMenuOption } from "./types";
import { AnimatePresence, motion } from "framer-motion";
import { MutableRefObject, useEffect, useRef, useState } from "react";

export interface LanguageSelectorProps {
  id: string;
  open: boolean;
  disabled?: boolean;
  onToggle: () => void;
  onChange: (
    value: SelectMenuOption["value"],
    lng: SelectMenuOption["lng"]
  ) => void;
  selectedValue: SelectMenuOption;
}

export default function LanguageSelector({
  id,
  open,
  disabled = false,
  onToggle,
  onChange,
  selectedValue,
}: LanguageSelectorProps) {
  const { t } = useTranslation();
  const ref = useRef<HTMLDivElement>(null);
  const [query, setQuery] = useState("");

  const dropdownContent: React.ReactNode = open ? (
    <motion.div>
      <motion.ul
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        transition={{ duration: 0.1 }}
        className="absolute z-10 mt-1 w-full shadow-lg max-h-80 rounded-md text-base ring-1 ring-black bg-white focus:outline-none sm:text-sm"
        tabIndex={-1}
        role="listbox"
        aria-labelledby="listbox-label"
      >
        <div className="max-h-64 scrollbar scrollbar-track-gray-100 scrollbar-thumb-gray-300 hover:scrollbar-thumb-gray-600 scrollbar-thumb-rounded scrollbar-thin overflow-y-scroll">
          {COUNTRIES.filter((country) =>
            country.title.toLowerCase().startsWith(query.toLowerCase())
          ).length === 0 ? (
            <li className="cursor-default select-none relative py-2 pl-3 pr-9">
              {t("noLanguageFound")}
            </li>
          ) : (
            COUNTRIES.filter((country) =>
              country.title.toLowerCase().startsWith(query.toLowerCase())
            ).map((value, index) => (
              <li
                key={`${id}-${index}`}
                className="cursor-default select-none relative py-2 pl-3 pr-9 flex items-center bg-white hover:bg-gray-50 transition"
                role="option"
                onClick={() => {
                  onChange(value.value, value.lng);
                  setQuery("");
                  onToggle();
                }}
              >
                <img
                  alt={value.value}
                  src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${value.value}.svg`}
                  className="inline mr-2 h-4 rounded-sm"
                />
                <span className="font-normal truncate">{value.title}</span>
              </li>
            ))
          )}
        </div>
      </motion.ul>
    </motion.div>
  ) : null;

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (ref.current && !ref.current.contains(event.target as Node) && open) {
        onToggle();
        setQuery("");
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [open, onToggle]);

  return (
    <div ref={ref}>
      <div className="mt-1 relative">
        <button
          type="button"
          className={`${
            disabled ? "bg-neutral-100" : "bg-white"
          } relative w-full border border-gray-300 rounded-md shadow-sm pl-3 pr-10 py-2 text-left cursor-default focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 sm:text-sm`}
          aria-haspopup="listbox"
          aria-expanded={open}
          aria-labelledby="listbox-label"
          onClick={onToggle}
          disabled={disabled}
        >
          <span className="truncate flex items-center">
            <img
              alt={selectedValue.value}
              src={`https://purecatamphetamine.github.io/country-flag-icons/3x2/${selectedValue.value}.svg`}
              className="inline mr-2 h-4 rounded-sm"
            />
            {selectedValue.title}
          </span>
          <span
            className={`absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none ${
              disabled ? "hidden" : ""
            }`}
          >
            <svg
              className="h-5 w-5 text-gray-400"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
              fill="currentColor"
              aria-hidden="true"
            >
              <path
                fillRule="evenodd"
                d="M10 3a1 1 0 01.707.293l3 3a1 1 0 01-1.414 1.414L10 5.414 7.707 7.707a1 1 0 01-1.414-1.414l3-3A1 1 0 0110 3zm-3.707 9.293a1 1 0 011.414 0L10 14.586l2.293-2.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414z"
                clipRule="evenodd"
              />
            </svg>
          </span>
        </button>

        <AnimatePresence>{dropdownContent}</AnimatePresence>
      </div>
    </div>
  );
}
