import HomeComponent from "./Home";
import Mission from "./Mission";
import Team from "./Team";
import Footer from "../../shared/core/Footer/Footer";

export default function Home() {

  return (
    <div className="bg-bg-primary" id="home">
      <HomeComponent />
      <Mission />
      <Team />
      <Footer />
    </div>
  );
}
