import { Icon as FeatherIcon } from "react-feather";

interface BenefitProps {
  number: number;
  title: string;
  items: string[];
  icon: FeatherIcon;
}

export default function Benefit({
  number,
  title,
  items,
  icon: Icon,
}: BenefitProps) {
  return (
    <div>
      <div className="hidden md:flex items-center">
        <div className="relative overflow-hidden">
          <div className="text-[250px] text-secondary font-source-bold leading-none">
            {number}
          </div>
          <div className="absolute top-0 right-0 w-full h-full border-t-[2px] border-t-text-white border-l-[2px] border-l-text-white transform -rotate-45 origin-top-right"></div>
          <div className="absolute top-3 right-0 w-full h-full transform rotate-45 origin-bottom-left bg-text-white" />
        </div>

        <div className="flex flex-col">
          <div className="ml-3 text-primary text-h4 font-source-bold flex items-center gap-x-4">
            <Icon className="size-8" />
            {title}
          </div>
          <ul className="text-primary text-[17px] flex flex-col gap-y-4 font-source-regular w-[450px] mt-[30px]">
            {items.map((item, index) => (
              <li key={index}>
                <p className="ml-4 text-text-black flex items-center relative pl-4 before:content-[''] before:absolute before:left-0 before:top-1/2 before:-translate-y-1/2 before:w-2 before:h-2 before:bg-primary before:rounded-full">
                  {item}
                </p>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="flex md:hidden space-x-4 mb-4">
        <div className="flex flex-col items-center">
          <div className="w-16 h-16 bg-bg-primary rounded-full drop-shadow-lg flex justify-center items-center">
            <Icon className="text-primary w-8 h-8" />
          </div>
          <div className="border-l-4 flex-grow mt-4 rounded-md border-primary" />
        </div>

        <div className="font-source-regular text-small leading-[32px] pt-4">
          <h4 className="text-h4 font-source-semibold text-primary leading-none pb-4">
            {title}
          </h4>
          <ul className="list-disc text-primary text-small font-source-regular">
            {items.map((item, index) => (
              <li key={index}>
                <p className="text-text-black">{item}</p>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
}
