import { faCircleDot, faEye, faGem } from "@fortawesome/free-regular-svg-icons";
import MissionComponent from "../../shared/components/Mission/Mission";
import { useTranslation } from "react-i18next";

export default function Mission() {
  const { t } = useTranslation();

  return (
    <div className="flex xl:min-h-[calc(100dvh)] flex-col items-center">
      <h1 className="text-h2 text-secondary text-center font-source-bold py-6 w-[95%] md:w-full md:text-center">
        {t('whatAre')} <span className="text-primary">{t('yaldo')}'s</span> {t('missionVissionValues')}
      </h1>

      <div className="grid xl:grid-cols-3 grid-cols-1 grid-flow-row-dense gap-x-24 gap-y-4 content-center flex-grow">
        <MissionComponent
          icon={faCircleDot}
          picture="images/missie_1.jpg"
          thema={t('mission')}
          text={t('missionText')}
          iconColor="bg-icon-medium"
        />
        <MissionComponent
          icon={faEye}
          picture="images/missie_2.jpg"
          thema={t('vision')}
          text={t('visionText')}
          iconColor="bg-icon-light"
        />
        <MissionComponent
          icon={faGem}
          picture="images/missie_3.jpg"
          thema={t('values')}
          text={t('valuesText')}
          iconColor="bg-icon-dark"
        />
      </div>
    </div>
  );
}
