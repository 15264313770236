import { Link } from "react-router-dom";
import { JackInTheBox, Slide } from "react-awesome-reveal";
import { useTranslation } from "react-i18next";
import Nav from "../../shared/core/Nav/Nav";

export default function Home() {
  const { t } = useTranslation();

  return (
    <div id="home">
      <div className="hidden md:grid grid-rows-[200px_1fr_auto] min-h-[calc(100dvh)]">
        <div>
          <Nav />
        </div>

        <div className="container flex mx-auto items-center">
          <div className="space-y-10 xl:w-1/2 content-center">
            <Slide
              cascade
              triggerOnce={true}
              className="flex flex-col items-center xl:block"
            >
              <h1 className="text-h1 font-source-bold text-primary leading-tight text-center xl:text-start">
                <span className="text-secondary">
                  {t('discoverExperiences')},
                </span>
                <br></br>
                {t('withYaldo')}
              </h1>
              <p className="text-large font-source-regular w-2/3 text-text-black leading-normal text-center xl:text-start">
                {t('discoverExperiencesSubtitle')}
              </p>

              <div className="space-x-4 mt-20">
                <Link 
                  to="/experiencers"
                  className="text-large font-source-bold text-text-white uppercase bg-primary py-4 px-8 rounded-3xl hover:bg-secondary transition ease-in-out duration-300"
                >
                  {t('askBookActivityButton')}
                </Link>
                <Link 
                  to="/providers"
                  className="text-large font-source-bold text-text-white uppercase bg-primary py-4 px-8 rounded-3xl hover:bg-secondary transition ease-in-out duration-300"
                >
                  {t('askBecomePartnerButton')}
                </Link>
              </div>
            </Slide>
          </div>

          <div className="xl:w-1/2">
            <JackInTheBox triggerOnce={true} delay={300}>
              <img
                alt="computer"
                src="images/Laptop&Phone.png"
                className=" hidden xl:flex"
              />
            </JackInTheBox>
          </div>
        </div>

        <div className="h-fit">
          <img
            className="invert pb-1 w-8 mx-auto animate-bounce"
            src="images/yaldoScroll.png"
            alt="yaldo_scroll"
          />
        </div>
      </div>

      {/* Mobile and iPad */}
      <div className="grid grid-rows-[100px_1fr] md:hidden">
        <div>
          <Nav />
        </div>
        
        <div className="container flex mx-auto items-center">
          <div className="space-y-5 xl:w-1/2 content-center">
            <div className="flex flex-col items-center xl:block">
              <h1 className="text-h2 text-center font-source-bold w-full text-primary leading-tight xl:text-start">
                <span className="text-secondary">
                  {t('discoverExperiences')},
                </span>
                <br></br>
                {t('withYaldo')}
              </h1>
              <p className="text-small font-source-regular w-2/3 text-text-black leading-normal text-center xl:text-start">
                {t('discoverExperiencesSubtitle')}
              </p>

              <div className="space-x-4 mt-20">
                <Link 
                  to="/experiencers"
                  className="text-small font-source-bold text-text-white uppercase bg-primary py-4 px-4 rounded-3xl hover:bg-secondary transition ease-in-out duration-300"
                >
                  {t('askBookActivityButton')}
                </Link>
                <Link 
                  to="/providers"
                  className="text-small font-source-bold text-text-white uppercase bg-primary py-4 px-4 rounded-3xl hover:bg-secondary transition ease-in-out duration-300"
                >
                  {t('askBecomePartnerButton')}
                </Link>
              </div>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  );
}
