import {
  Upload,
  BookOpen,
  Heart,
  BarChart,
  Zap,
  Link,
} from "react-feather";
import { Fade } from "react-awesome-reveal";
import { useTranslation } from "react-i18next";
import Benefit from "../../shared/components/Benefit/Benefit";

export default function Benefits() {
  const { t } = useTranslation();

  return (
    <div
      className="flex xl:min-h-[calc(100dvh)] flex-col space-y-10 items-center"
      id="benefits"
    >
      <h1 className="mt-10 text-h2 text-secondary font-source-bold w-[95%] md:w-full md:text-center">
        <span className="text-primary">{t('benefits')}</span> {t('speciallyForYou')}
      </h1>

      <div className="grid xl:grid-cols-3 grid-cols-1 grid-flow-row-dense flex-grow">
        <Fade cascade triggerOnce={true} className="content-center">
          <Benefit
            number={1}
            icon={Upload}
            title={t('createAndShare')}
            items={[
              t('createYaldoFast'),
              t('configureEasily'),
              t('shareWithWorld'),
            ]}
          />
          <Benefit
            number={2}
            icon={BookOpen}
            title={t('receiveBookings')}
            items={[
              t('receiveAndAcceptPotentialExperiencers'),
              t('manageAll'),
              t('planInAgenda'),
            ]}
          />
          <Benefit
            number={3}
            icon={Heart}
            title={t('shareYourPassion')}
            items={[
              t('easyPassionSharing'),
              t('everythingViaOnePlatform'),
              t('offerExperiencesAndSpread'),
            ]}
          />
          <Benefit
            number={4}
            icon={BarChart}
            title={t('reachMoreCustomers')}
            items={[
              t('experiencersSearchForActivities'),
              t('discoverNewProviders'),
              t('wordOfMouthAdvertisements'),
            ]}
          />
          <Benefit
            number={5}
            icon={Zap}
            title={t('boostYourSales')}
            items={[
              t('convinceNewCustomers'),
              t('potentialCustomersCanBookYourYaldo'),
              t('sellYourProducts'),
            ]}
          />
          <Benefit
            number={6}
            icon={Link}
            title={t('constructValuableRelationships')}
            items={[
              t('enforceRelationships'),
              t('offerExclusiveOffers'),
              t('receiveFeedback'),
            ]}
          />
        </Fade>
      </div>
    </div>
  );
}
