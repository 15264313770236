import { useTranslation } from "react-i18next";
import Hexagon from "../../shared/components/Hexagon/Hexagon";

export default function Team() {
  const { t } = useTranslation();

  const teamMembers = [
    {
      name: "Jari Miers",
      job_titles: [t("teamTitle1"), t("teamTitle2")],
      image: "images/yaldoteam_jari.jpg",
      linked_in: "https://www.linkedin.com/in/jari-miers-879b011a3",
    },
    // {
    //   name: "Ben Leys",
    //   job_titles: [t('teamTitle1'), t('teamTitle2')],
    //   image: "images/yaldoteam_ben.jpg",
    //   linked_in: "https://www.linkedin.com/in/ben-leys-7556a9234",
    // },
  ];

  return (
    <div className="min-h-screen flex flex-col items-center p-4 xl:min-h-[calc(100dvh-200px)]">
      <h1 className="text-h2 text-center text-secondary font-source-bold w-full md:text-center">
        {t("meet")} <span className="text-primary">{t("yaldo")}</span>{" "}
        {t("team")}
      </h1>

      <div className="flex flex-col my-auto justify-center">
        <div className="-mt-5 flex flex-wrap justify-center gap-4">
          {teamMembers.slice(0, 4).map((member, index) => (
            <Hexagon key={index} {...member} />
          ))}
        </div>
        <div className="flex flex-wrap justify-center gap-4 xl:mt-[-40px]">
          {teamMembers.slice(4, 8).map((member, index) => (
            <Hexagon key={index} {...member} />
          ))}
        </div>
      </div>
    </div>
  );
}
