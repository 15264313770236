import { Link } from "react-router-dom";
import { Send } from "react-feather";
import { useTranslation } from "react-i18next";

export default function Footer() {
  const { t } = useTranslation();

  return (
    <footer className="bg-primary py-4">

      {/* Desktop footer */}
      <div className="hidden lg:flex flex-row flex-wrap justify-around items-center" id="footer">
        <div>
          <div className="flex items-center justify-start space-x-2 mb-6">
            <img
              src="./images/yaldo_icon_white.svg"
              className="w-[25px] lg:w-[32px] pt-4"
            />
            <img
              src="./images/logo_white.svg"
              className="w-[150px] lg:w-[170px]"
            />
          </div>
          <div className="border-t-[1px] text-text-white flex flex-wrap justify-center pt-6 gap-x-8">
            <a className="cursor-pointer font-source-semibold text-small hover:text-secondary transition ease-in">
              {t('termsAndConditions')}
            </a>
            <Link 
              to="/privacy-policy"
              className="cursor-pointer font-source-semibold text-small hover:text-secondary transition ease-in"
            >
              {t('privacyPolicy.text')}
            </Link>
            <a className="cursor-pointer font-source-semibold text-small hover:text-secondary transition ease-in">
              {t('cookieSettings')}
            </a>
          </div>
        </div>
        <div className="pb-6 flex flex-col items-center">
          <p className="text-large text-text-white font-source-semibold pb-4 leading-[30px]">
            {t('stayUpToDateNews')}
          </p>
          <div className="flex rounded-lg shadow-sm">
            <input
              type="email"
              placeholder={t('yourEmail')}
              className="py-3 px-4 block w-[300px] shadow-sm rounded-s-[20px] text-small placeholder-primary font-source-semibold focus:outline-none focus:ring-0"
            />
            <div className="py-[7px] px-[7px] inline-flex justify-center items-center gap-x-2 rounded-e-[20px] bg-text-white">
              <div className="bg-secondary p-3 rounded-[15px] cursor-pointer hover:bg-primary transition ease-in">
                <Send className="text-text-white w-8" />
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col flex-wrap justify-center">
          <img
            src="images/footer/GooglePlay.png"
            className="mb-1 w-[11rem] cursor-pointer"
            alt="googleplay"
          />
          <img
            src="images/footer/AppStore.png"
            className="mt-1 w-[11rem] cursor-pointer"
            alt="appstore"
          />
        </div>
      </div>

      {/* Mobile / Tablet footer */}
      <div className="lg:hidden flex flex-col">
        <div className="text-text-white flex flex-wrap space-x-4 justify-center">
          <a className="font-source-semibold text-small">
            {t('termsAndConditions')}
          </a>
          <a className="font-source-semibold text-small">
            {t('privacyPolicy.text')}
          </a>
          <a className="font-source-semibold text-small">
            {t('cookieSettings')}
          </a>
        </div>
        <div className="flex flex-wrap justify-center py-8">
          <img
            src="images/footer/GooglePlay.png"
            className="w-[11rem] md:w-[12rem] cursor-pointer"
            alt="googleplay"
          />
          <img
            src="images/footer/AppStore.png"
            className="w-[11rem] md:w-[12rem] cursor-pointer"
            alt="appstore"
          />
        </div>
        <div className="flex flex-col items-center w-full md:w-auto md:block self-center">
          <p className="text-small md:text-large text-text-white font-source-semibold pb-3">
            {t('stayUpToDateNews')}
          </p>
          <div className="flex rounded-lg shadow-sm w-2/3 md:w-full">
            <input
              type="email"
              placeholder={t('yourEmail')}
              className="py-3 px-4 block w-full shadow-sm rounded-s-full text-small placeholder-secondary font-source-semibold focus:outline-none focus:ring-0"
            />
            <div className="py-[7px] px-[7px] inline-flex justify-center items-center gap-x-2 rounded-e-full bg-text-white">
              <div className="bg-primary p-3 rounded-full cursor-pointer hover:bg-secondary">
              <Send className="text-text-white w-7" />
              </div>
            </div>
          </div>
        </div>
      </div>
      
    </footer>
  );
}
