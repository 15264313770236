import Footer from "../../shared/core/Footer/Footer";
import PrivacyPolicyComponent from "./PrivacyPolicy";

export default function PrivacyPolicy() {

  return (
    <div className="bg-bg-primary" id="privacypolicy">
      <PrivacyPolicyComponent />
      <Footer />
    </div>
  );
}
