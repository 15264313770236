import { Fade, Slide } from "react-awesome-reveal";
import { useTranslation } from "react-i18next";

function Phone() {
  const { t } = useTranslation();

  return (
    <div className="relative z-10 overflow-hidden">
      <Slide direction="right" triggerOnce={true}>
        <img
          src="images/mockup-phone-2.png"
          className="w-[50%] h-[50%] relative bottom-[5%] left-[12%]"
          alt="yaldo_phone"
        />
      </Slide>
      <div className="absolute rounded-full w-[70%] h-[0] pb-[60%] bg-primary top-0 left-0 border-[40px] border-secondary -z-40"></div>

      <Fade delay={1000} cascade triggerOnce={true}>
        <div className="absolute top-2/3 right-1/4 bg-primary px-8 py-4 text-text-white text-[20px] font-source-semibold rounded-tl-[40px] rounded-tr-[10px] rounded-br-[40px] rounded-bl-[10px]">
          {t('basedOnInterests')}
        </div>
        <div className="absolute top-1/4 right-[55%] bg-primary px-8 py-4 text-text-white text-[20px] font-source-semibold rounded-tr-[40px] rounded-tl-[10px] rounded-bl-[40px] rounded-br-[10px]">
          {t('inviteFriendsEasily')}
        </div>
      </Fade>
    </div>
  );
}

export default Phone;
